import React from 'react';
import './advertise.css';

const AdvertisePage = () => {

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://menadirectoryusa.com/api/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title: e.target.businessName.value,
                sub_title: e.target.shortDescription.value,
                address: e.target.fullAddress.value,
                website: e.target.website.value,
                email: e.target.email.value,
                phone: e.target.phoneNumber.value,
                facebook: e.target.facebook.value,
                twitter: e.target.twitter.value,
                instagram: e.target.instagram.value,
                time_monday: e.target.monday.value,
                time_tuesday: e.target.tuesday.value,
                time_wednesday: e.target.wednesday.value,
                time_thursday: e.target.thursday.value,
                time_friday: e.target.friday.value,
                time_saturday: e.target.saturday.value,
                time_sunday: e.target.sunday.value,
                image_1: e.target.logo.value,
                description: e.target.description.value,
                features: e.target.features.value,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            if (data['status'] === 'success') {
                alert('We have received your request. We will get back to you soon.');
            } else {
                alert('Something went wrong. Make sure you have filled all the fields.');
            }
        }
        )
    }

    return (
        <div className="p-page" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)` }}>
            <div className="card card-aa">
                <div className="h3 bg-gold p-3 text-center radius d-shadow">Advertise your Business with MENA</div>
                <div className="card-body text-start p-4">
                    <form onSubmit={handleSubmit}>
                        <div className="h5">Basic Details</div>
                        <div className="row w-100 px-0">
                            <div className="col">
                                <label className="form-label">Business Name</label>
                                <input type="text" className="form-control" placeholder="" name="businessName" />
                            </div>
                            <div className="col">
                                <label className="form-label">Short Description</label>
                                <input type="text" className="form-control" placeholder="" name="shortDescription" />
                            </div>
                        </div>
                        <div className="row px-0 w-100">
                            <div className="col">
                                <label className="form-label">Full Address</label>
                                <input type="text" className="form-control" placeholder="" name="fullAddress" />
                            </div>
                        </div>
                        
                        <div className="h5 mt-4 mb-0">Contact Details</div>
                        <div className="row w-100 p-0 mt-3">
                            <div className="col">
                                <label className="form-label">Website</label>
                                <input type="text" className="form-control mb-2" placeholder="" name="website" />
                            </div>
                            <div className="col">
                                <label className="form-label">Email address</label>
                                <input type="text" className="form-control" placeholder="" name="email" />
                            </div>
                        </div>
                        <div className="row w-100 p-0">
                            <div className="col">
                                <label className="form-label">Phone Number</label>
                                <input type="text" className="form-control mb-2" placeholder="" name="phoneNumber" />
                            </div>
                            <div className="col">
                                <label className="form-label">Facebook</label>
                                <input type="text" className="form-control" placeholder="" name="facebook" />
                            </div>
                        </div>
                        <div className="row w-100 p-0">
                            <div className="col">
                                <label className="form-label">Twitter</label>
                                <input type="text" className="form-control mb-2" placeholder="" name="twitter" />
                            </div>
                            <div className="col">
                                <label className="form-label">Instagram</label>
                                <input type="text" className="form-control" placeholder="" name="instagram" />
                            </div>
                        </div>
                        <div className="h5 mt-4 mb-0">Working Hours</div>
                        <div className="row w-100 p-0 pt-3">
                            <div className="col">
                                <label className="form-label">Monday</label>
                                <input type="text" className="form-control" placeholder="" name="monday" />
                            </div>
                            <div className="col">
                                <label className="form-label">Tuesday</label>
                                <input type="text" className="form-control" placeholder="" name="tuesday" />
                            </div>
                            <div className="col">
                                <label className="form-label">Wednesday</label>
                                <input type="text" className="form-control" placeholder="" name="wednesday" />
                            </div>
                        </div>
                        <div className="row w-100 p-0 pt-2">
                            <div className="col">
                                <label className="form-label">Thursday</label>
                                <input type="text" className="form-control" placeholder="" name="thursday" />
                            </div>
                            <div className="col">
                                <label className="form-label">Friday</label>
                                <input type="text" className="form-control" placeholder="" name="friday" />
                            </div>
                            <div className="col">
                                <label className="form-label">Saturday</label>
                                <input type="text" className="form-control" placeholder="" name="saturday" />
                            </div>
                        </div>
                        <div className="row w-100 p-0 pt-2">
                            <div className="col">
                                <label className="form-label">Sunday</label>
                                <input type="text" className="form-control" placeholder="" name="sunday" />
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                        <div className="h5 mt-4 mb-3">Additional Information</div>
                        <label className="form-label">Business  Logo Link</label>
                        <input type="text" className="form-control mb-2" placeholder="" name="logo" />
                        <label className="form-label">Business Description</label>
                        <textarea className="form-control mb-2" placeholder="" name="description" />
                        <label className="form-label">Services or Featured Menu Items <i>(Comma separated)</i></label>
                        <textarea className="form-control" placeholder="" name="features" />
                        <button type="submit" 
                            className="btn btn-danger mt-3"
                            style={{ height: '6vh', backgroundColor: '#e84c3c' }}
                        >Send Request</button>
                        
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdvertisePage;
