import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './landing.css';
import { useNavigate } from 'react-router-dom';

const states = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
  "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
  "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
  "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
  "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
  "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma",
  "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
  "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
  "West Virginia", "Wisconsin", "Wyoming"
];

const Landing = () => {
  const cards = [
    { id: 1, title: "Arizona", image: "states/newyork.jpg" },
    { id: 2, title: "California", image: "states/newyork.jpg" },
    { id: 3, title: "Colorado", image: "states/newyork.jpg" },
    { id: 4, title: "Florida", image: "states/newyork.jpg" },
    { id: 5, title: "Georgia", image: "states/newyork.jpg" },
    { id: 6, title: "Idaho", image: "states/newyork.jpg" },
    { id: 7, title: "Illinois", image: "states/newyork.jpg" },
    { id: 8, title: "Indiana", image: "states/newyork.jpg" },
    { id: 9, title: "Kentucky", image: "states/newyork.jpg" },
    { id: 10, title: "Kansas", image: "states/newyork.jpg" },
    { id: 11, title: "Louisiana", image: "states/newyork.jpg" },
    { id: 12, title: "Michigan", image: "states/newyork.jpg" },
    { id: 13, title: "Minnesota", image: "states/newyork.jpg" },
    { id: 14, title: "Missouri", image: "states/newyork.jpg" },
    { id: 15, title: "New Jersey", image: "states/newyork.jpg" },
    { id: 16, title: "New York", image: "states/newyork.jpg" },
    { id: 17, title: "Ohio", image: "states/newyork.jpg" },
    { id: 18, title: "Texas", image: "states/newyork.jpg" },
  ];

  const [selectedState, setSelectedState] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const navigate = useNavigate();  

  const handleSearch = () => {
    if (!searchKeyword && !selectedState) {
      alert('Please enter a search keyword or select a state to search');
      return;
    }
    const queryParams = new URLSearchParams();
    if (searchKeyword) {
      queryParams.append('keyword', searchKeyword);
    }
    if (selectedState) {
      queryParams.append('state', selectedState);
    }
    navigate(`/lists?${queryParams.toString()}`);
  };

  const goToContactUs = () => {
    navigate('/contact');
  }


  const handleCardClick = (state) => {
    navigate(`/lists?state=${state}`);
  };

  return (
    <div className="landing-container" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)` }}> 
      <div className="search-container">
        <div className="h1 text-light text-center">Arabic American Community Guide</div>
        <div className="h5 text-light mb-3">Middle Eastern And North Africa</div>
        <div className="search-bar-frame">
          <input
            type="text"
            className="form-control form-control-search"
            placeholder="Search..."
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
          <select
            className="form-select form-select-search"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
          <button
            className="btn btn-danger"
            style={{ height: '6vh', backgroundColor: '#e84c3c' }}
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>

      <div className="p-banner">
        <div className="w-100">
          <div className=" verticalCenter">
            <div className="d-shadow">
              <span className="h1 strong">ADVERTISE</span> <span className="h4">with <span className="strong">MENA</span></span>
            </div>
            <button className="btn btn-p" onClick={goToContactUs}>Contact Us Today!</button>
          </div>
        </div>
      </div>

      <div className="slider-container">
        {states.map((state) => (
          <div key={state} className="state-circle" onClick={() => handleCardClick(state)}>
            {state}
          </div>
        ))}
      </div>

    </div>
  );
};

export default Landing;
